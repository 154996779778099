var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6",staticStyle:{"padding-top":"3px"}},[_c('v-dialog',{attrs:{"max-width":550},on:{"click:outside":function () { return _vm.dialogOn = false; }},model:{value:(_vm.dialogOn),callback:function ($$v) {_vm.dialogOn=$$v},expression:"dialogOn"}},[_c('CreateCommunity')],1),_c('v-row',{staticClass:"py-2 px-4",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticStyle:{"border-radius":"10px !important"},attrs:{"elevation":"0","color":_vm.btnColor},on:{"click":function($event){_vm.dialogOn = true}}},[_vm._v(" "+_vm._s(_vm.$t('community.createButtonLabel'))+" ")]),_c('span',{staticClass:"mx-2"}),_c('v-switch',{staticStyle:{"transform":"translateY(6px)"},attrs:{"inset":"","color":"grey"},model:{value:(_vm.live),callback:function ($$v) {_vm.live=$$v},expression:"live"}}),_c('span',[_vm._v(" Live content ")]),_c('span',{staticClass:"mx-1"}),_c('span',{class:_vm.live ? 'live-indicator' : 'status-indicator offline'}),_c('v-text-field',{staticClass:"mx-4",staticStyle:{"border-radius":"12px"},attrs:{"filled":"","rounded":"","dense":"","hide-details":"","placeholder":"Search content","prepend-inner-icon":_vm.mdiMagnify},on:{"input":_vm.setTyping},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-sheet',{staticClass:"switch-hall-style",staticStyle:{"border-radius":"12px","display":"flex","justify-content":"center","align-items":"center","height":"40px","transition-duration":"0.5s"},attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3',"width":"90"}},[_c('v-btn-toggle',{attrs:{"color":_vm.$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-3',"mandatory":"","group":""},model:{value:(_vm.gridStyle),callback:function ($$v) {_vm.gridStyle=$$v},expression:"gridStyle"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiCompassOutline)+" ")])],1)]}}])},[_c('span',[_vm._v("Explore Communities")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":("grey " + (_vm.$vuetify.theme.dark ? 'darken' : 'lighten') + "-1")}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiLibraryOutline)+" ")])],1)]}}])},[_c('span',[_vm._v("Library: coming soon")])])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-20"},[_c('v-card',{staticClass:"hs-rounded-xl",staticStyle:{"position":"sticky","top":"30px"},attrs:{"outlined":!_vm.$vuetify.theme.dark,"elevation":"0","height":((_vm.$vuetify.breakpoint.xl ? 83 : 80) + "vh")}},[_vm._l((_vm.notes),function(note,index){return _c('Note',{key:index,attrs:{"index":index,"note":note,"noteId":note.id}})}),_c('div',{staticClass:"my-8"}),_c('div',[_c('v-sheet',{staticClass:"mx-4",staticStyle:{"border-radius":"14px"},attrs:{"color":_vm.optionColor}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Verified only ")]),_c('v-list-item-action',[_c('v-switch',{attrs:{"color":"tertiary","inset":"","dense":""}})],1)],1)],1)],1),_c('div',{staticClass:"my-4"}),_c('div',[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"mx-4 clickable",staticStyle:{"border-radius":"14px"},attrs:{"color":_vm.optionColor},on:{"click":_vm.doSomething}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Invitations ")]),_c('v-list-item-action',[_c('v-badge',{attrs:{"overlap":"","color":"red","value":10}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(" "+_vm._s(10)+" ")]),_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiSquareRoundedBadge)+" ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"my-2"}),_c('v-container',{staticClass:"bottom-menu"},[_c('v-btn',{staticStyle:{"border-radius":"12px !important","width":"100%"},attrs:{"large":"","elevation":"0","color":_vm.btnColor},on:{"click":function($event){_vm.dialogOn = true}}},[_vm._v(" create a community ")])],1)],2)],1),_c('v-col',{staticClass:"col-80 px-0 py-0"},[_c('ScrollPagination',{attrs:{"requestData":{
          'url': ("communities/" + (this.user.id)),
          'params': {
            sort: {
              createdAt: -1
            }
          }
        },"countData":{
          'url': ("communities/" + (this.user.id) + "/count"),
        },"thin":false,"rootHeight":"86vh","rowClass":"justify-content: start;","rootClass":'row px-3'},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('CommunityCard',{key:("hall_" + (props.index)),staticClass:"mb-4",attrs:{"id":props.item.id,"thumbnail":props.item.thumbnail,"name":props.item.name,"about":props.item.about}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }