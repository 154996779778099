<template>
  <v-dialog
    v-model="updateOn"
    :max-width="550"
    @click:outside="$emit('close')"
  >
    <v-card
      rounded="xl"
      :height="500"
      style="position: relative;"
    >
      <v-container>
        <v-row justify="center">
          <v-col>
            <Scrollbar
              style="height: 450px;"
              :scrollUpdateOn="false"
              :thin="true"
            >
              <v-container>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="px-6"
                  >
                    <CoverUploader
                      :settingsOn="false"
                      :imgSrc="cover"
                      @setCover="setCover"
                    />

                    <v-list-item
                      class="px-2"
                      style="transform: translateY(-50px); position: absolute; max-width: 100%;"
                    >
                      <ThumbnailUploader
                        :active="bundleOn"
                        :imgSrc="thumbnail"
                        @setThumbnail="setThumbnail"
                      />
                    </v-list-item>

                    <v-form ref="newHall">
                      <v-row
                        class="mt-16"
                        justify="start"
                        align="center"
                      >
                        <v-col cols="12">
                          <v-text-field
                            v-model="name"
                            placeholder="Name"
                            validate-on-blur
                            :rules="[v => !!v || 'The community needs a name']"
                            style="border-radius: 10px;"
                            dense
                            filled
                          />

                          <v-textarea
                            v-model="about"
                            auto-grow
                            filled
                            placeholder="About"
                            :counter="560"
                            validate-on-blur
                            :rules="[v => !!v || 'What is this community about?']"
                            style="border-radius: 15px"
                          />
                        </v-col>
                      </v-row>
                    </v-form>

                    <PrivacySettings @settings="setPrivacySettings" />
                  </v-col>
                </v-row>
                
                <v-row justify="center">
                  <v-col cols="11">
                    <v-btn
                      block
                      large
                      style="border-radius: 15px"
                      depressed
                      :loading="loading"
                      @click="submit"
                    >
                      {{ $t('community.createButtonLabel') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </Scrollbar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import FileInputBtn from '@components/File/FileInputBtn'
import CoverUploader from '@components/Content/CoverUploader'
import FileInput from '@components/File/FileInput'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { ThumbnailUploader } from '@components/Image'
import { MediaURL, PrivacySettings } from '@components'
import API from '@api'

export default {
  components: {
    FileInputBtn,
    CoverUploader,
    FileInput,
    Scrollbar,
    ThumbnailUploader,
    PrivacySettings
  },

  props: {
    community: {
      type: Object,
      default() {
        return {}
      }
    },
    updateOn: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      loading: false,
      name: '',
      about: '',
      thumbnail: '',
      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },
      cover: '',
      coverObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  },

  watch: {
    community: {
      handler: function() {
        this.resetData()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile/id'
    })
  },

  methods: {
    setPrivacySettings(settings) {},
    
    async submit() {
      this.loading = true

      if (this.$refs.newHall.validate()) {

        if (!this.community.id) {
          // Create new community
          if (this.name && this.about) {
            const images = {}
            if (this.thumbnailObj.mediaSrc) {
              const [thumbnailUrl] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
              images.thumbnail = thumbnailUrl
            }

            if (this.coverObj.mediaSrc) {
              const [coverUrl] = await this.getMediaUrl([this.coverObj], 'images/covers')
              images.cover = coverUrl
            }
            
            const { id } = await API().post(`hall/${this.user.id}`, {
              name: this.name,
              about: this.about,
              ...images // other data to initialize community
            })

            await API().post(`member/${this.user.id}`, {
              role: 'owner',
              active: true,
              profile: this.myProfile,
              community: id
            })

            this.$router.push(`/halls/hall?id=${id}`)
          }
        } else {
          // Update existing community
          if (this.thumbnailObj.mediaSrc) {
            const [thumbnailUrl] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
            this.setProp({ prop: 'thumbnail', value: thumbnailUrl })
          }

          if (this.coverObj.mediaSrc) {
            const [coverUrl] = await this.getMediaUrl([this.coverObj], 'images/covers')
            this.setProp({ prop: 'cover', value: coverUrl })
          }

          if (this.name != this.community.name) {
            this.setProp({ prop: 'name', value: this.name })
          }

          if (this.about != this.community.about) {
            this.setProp({ prop: 'about', value: this.about })
          }
        }

        setTimeout(() => {
          this.resetData()
        }, 100)    

      }

      setTimeout(() => {
        this.loading = false
      }, 100)  
    },

    setProp({ prop, value }) {
      this.update(value, prop)
      this.$emit('setProp', { prop, value, index: -1 })
    },

    async update(value, prop) {
      API().put(`hall/${this.user.id}`, {
        [prop]: value
      }, {
        params: {
          id: this.community.id
        }
      })
    },

    setCover(coverObj) {
      this.coverObj = coverObj
    },

    setThumbnail(thumbnailObj) {
      this.thumbnailObj = thumbnailObj
    },

    resetData() {
      this.coverObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      
      this.name = this.community.name
      this.about = this.community.about
      this.thumbnail = this.community.thumbnail
      this.cover = this.community.cover
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>  